<template>
  <div class="user-guide">
    <div class="user-guide__header">
      <ulsemo_stepper
        v-if="current_step > 1 && current_step < 6"
        class="user-guide__stepper"
        :steps="[1,2,3,4]"
        :current_step="current_step - 1"
      />
      <h1
        class="user-guide__title"
        :class="{
          'user-guide__title--large': current_step === 1 || current_step > 8,
          'user-guide__title--margin-top': current_step > 5,
        }"
        v-html="$translate(`user_guide.step_${current_step}.${skipping_guide ? 'skip_title' : 'title'}`)"  
      />
    </div>
    <div class="user-guide__content">
      <router-view
        name="guide_content"
        :data="product_data"
        :store_data="store_data"
        :product="product"
        :collection="collection"
        :step_number="current_step"
        :skipping_guide="skipping_guide"
        @next_step="next_step"
        @skip_step="skip_step"
        @update_value="update_value"
        @create_demo_product="create_demo_product"
      />
    </div>
    <div class="user-guide__footer">
      <basic_button
        v-if="show_back_button"
        :text="$translate('user_guide.back')"
        @click.native="prev_step"
      />
      <highlighted_button
        v-if="show_next_button"
        :text="next_button_text"
        @click.native="next_step"
        :disabled="next_button_disabled"
      />
      <basic_button
        v-if="show_skip_button"
        :text="skip_button_text"
        @click.native="skip_step"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex"
import html_entity_encoder from "urlencode"
import basic_button from "../basic_button"
import highlighted_button from "../highlighted_button"
import ulsemo_stepper from "./ulsemo_stepper"
import { ADMIN, ADMIN_STORE, USER_STORE } from "../../../constants/others_constants"
import { BUILD_NEW_STORE, UPDATE_SUBSCRIPTION_PAYED } from "../../../stores/Admin/constants"
import empty_product_object from "../../../constants/empty_objects/empty_product_object"
import empty_collection_object from "../../../constants/empty_objects/empty_collection_object"
import { description_from_message_splitter } from "../../../../../data/other_constants"
import { GUIDE } from "../../../../../data/page_types"
import { default_language, SHARED_STORE } from "../../../../Shared/constants/other"
import empty_image_object from "../../../constants/empty_objects/empty_image_object"
import { ADMIN_LOGIN } from "../../../../Shared/stores/Shared/constants"
import project_styles from "../../../../../data/project_styles"
import un_bind from "../../../../Shared/methods/un_bind"

const default_product_data = {
  product_title: "",
  product_description: "",
  product_images: null,
  product_price: null,
  product_inventory: null,
  collection_title: "",
  collection_image: null,
}
const default_content_data = {
  store_name: "",
  store_languages: [],
  store_colors: un_bind(project_styles),
  store_logo: null,
  collection_banners: null,
  popular_products: null,
  highlighted_products: null,
  menu_items: null,
}

export default {
  components: {
    basic_button,
    highlighted_button,
    ulsemo_stepper,
  },
  data() {
    return {
      current_step: 1,
      skipping_guide: false,
      product_data: default_product_data,
      store_data: default_content_data,
      product: {},
      collection: {},
      product_keys: [
        "product_title",
        "product_description",
        "product_images",
        "product_price",
        "product_inventory",
      ],
      translation_keys: [
        "product_title",
        "product_description",
      ],
      demo_product_data: {
        product_title: this.$translate("user_guide.demo_product.title"),
        product_description: this.$translate("user_guide.demo_product.description"),
        product_images: [empty_image_object("/static/user_guide/demo_product_image_1.jpeg")],
        product_price: 10,
        product_inventory: 10,
      },
      demo_collection_data: {
        collection_title: this.$translate("user_guide.demo_collection.title"),
        collection_image: "/static/user_guide/demo_collection_image_1.jpeg"
      },
      variant_keys: ["product_price", "product_inventory"],
      collection_keys: ["collection_title", "collection_image"],
    }
  },
  computed: {
    ...mapState(USER_STORE, ["projects"]),
    correct_step_component() {
      return [6,7].includes(this.current_step) ? "step_6" : `step_${this.current_step}`
    },
    show_skip_button() {
      return [1, 6, 7].includes(this.current_step)
    },
    show_next_button() {
      return this.current_step !== 1
    },
    show_back_button() {
      return this.current_step > 1
    },
    skip_button_text() {
      return this.current_step === 1 ?
        this.$translate("user_guide.skip_guide") :
        this.$translate("user_guide.skip")
    },
    next_button_text() {
      if (this.current_step === 8) return this.$translate("user_guide.create_store")

      return this.$translate("user_guide.next")
    },
    user_filled_necessary_inputs() {
      return Boolean(this.current_step === 8 && (this.store_data.store_name && this.store_data.store_languages.length))
    },
    next_button_disabled() {
      return this.current_step === 8 ? !this.user_filled_necessary_inputs : false
    }
  },
  watch: {
    current_step(val) {
      this.$router.push({ path: `/${GUIDE}/step_${val}` })
    },
    $route(val) {
      this.current_step = parseInt(val.name)
    }
  },
  mounted() {
    this.current_step = parseInt(this.$route.name)
    if (this.current_step > 5) this.create_product_and_collection()
  },
  beforeDestroy() {
    this.reset_guide()
  },
  methods: {
    ...mapActions(ADMIN, {
      build_store: BUILD_NEW_STORE
    }),
    ...mapActions(SHARED_STORE, {
      admin_login: ADMIN_LOGIN,
    }),
    ...mapMutations(ADMIN_STORE, {
      update_subscription_payed: UPDATE_SUBSCRIPTION_PAYED
    }),
    reset_guide() {
      this.product_data = default_product_data
      this.store_data = default_content_data
      this.product = {}
      this.collection = {}
    },
    fill_in_missing_product_data() {
      this.product_keys.forEach(key => {
        if (!this.product_data[key]) this.product_data[key] = this.demo_product_data[key]
      })
      this.collection_keys.forEach(key => {
        if (!this.product_data[key]) this.product_data[key] = this.demo_collection_data[key]
      })
    },
    update_value(val, type) {
      const entity_being_updated = [
        ...this.product_keys,
        ...this.collection_keys
      ].includes(type) ? "product_data" : "store_data"

      if ("store_colors" === type) this.store_data.store_colors.colors = val
      else this[entity_being_updated][type] = val

      if (this.product.id && this.product_keys.includes(type)) {
        this.update_product_value(val, type)
      } else if (this.collection.id && this.collection_keys.includes(type)) {
        this.update_collection_value(val, type)
      }
    },
    update_product_value(val, type) {
      if (this.variant_keys.includes(type)) {
        this.product.variants[0].inventory_quantity = this.product_data.product_inventory
        this.product.variants[0].translations[default_language].price = this.product_data.product_price
      }
      else if (this.translation_keys.includes(type)) {
        this.product.translations = {
          [default_language]: {
            title: this.product_data.product_title,
            body_html: this.product_data.product_description + description_from_message_splitter,
          }
        }
      }
      else this.product[type.replace("product_", "")] = val

      this.update_collection_value([{
        ...this.product,
        collections: []
      }], "products")
    },
    update_collection_value(val, type) {
      this.collection[type.replace("collection_", "")] = val

      if (type === "collection_title") {
        this.collection.handle = html_entity_encoder(val)
      }
    },
    skip_guide() {
      this.current_step = 8
      this.skipping_guide = true
    },
    async next_step() {
      window.scrollTo(0, 0)

      if (this.current_step === 1) this.product_data = default_product_data
      if (this.current_step === 6) this.store_data = default_content_data
      if (this.current_step === 8) return this.create_new_store()

      this.current_step++

      if (this.current_step === 6 && !this.product.id) this.create_product_and_collection()
    },
    skip_step() {
      if (this.current_step === 1) return this.skip_guide()
      this.current_step = 1 + this.current_step
    },
    prev_step() {
      if (this.skipping_guide) {
        this.skipping_guide = false
        this.current_step = 1
      } else {
        this.current_step--
      }
    },
    create_product_and_collection() {
      this.fill_in_missing_product_data()
      const collection = 
        this.product_data.collection_title ? 
          empty_collection_object(
            this.product_data.collection_title,
            html_entity_encoder(this.product_data.collection_title),
            "",
            [],
            true,
            this.product_data.collection_image,
          ) :
          null

      const product = empty_product_object(
        collection,
        null,
        true,
        [default_language],
        {
          [default_language]: {
            title: this.product_data.product_title,
            body_html: this.product_data.product_description + description_from_message_splitter,
          }
        },
        Number(this.product_data.product_price || 0),
        this.product_data.product_images,
        Number(this.product_data.product_inventory || 0),
        {
          published_at: new Date().toISOString(),
          published: true,
        }
      )

      if (collection) collection.products.push({
        ...product,
        collections: []
      })

      this.product = product
      if (collection) this.collection = collection
    },
    async create_new_store() {
      if (!this.user_filled_necessary_inputs) return

      const {new_store_id} = await this.build_store(this.skipping_guide ?
        {
          store_name: this.store_data.store_name,
          store_languages: this.store_data.store_languages,
        } :
        {
          product_data: {
            products: [this.product],
            collections: this.collection.id ? [this.collection] : [],
          },
          ...this.store_data
        }
      )
      this.update_subscription_payed(false)

      this.$router.replace(({
        path: `/billing/${new_store_id}`,
        query: {
          guide: true
        }
      }))
    },
    create_demo_product() {
      const product_price = 100
      const product_inventory = 100
      const collection = empty_collection_object(
        this.$translate("user_guide.demo_collection.title"),
        html_entity_encoder(this.$translate("user_guide.demo_collection.title")),
        "",
        [],
        true,
        "/static/user_guide/demo_collection_image_1.jpeg"
      )
      const product = empty_product_object(
        collection,
        null,
        true,
        [default_language],
        {
          [default_language]: {
            title: this.$translate("user_guide.demo_product.title"),
            body_html: this.$translate("user_guide.demo_product.description"),
          }
        },
        product_price,
        [
          empty_image_object("/static/user_guide/demo_product_image_1.jpeg")
        ],
        product_inventory,
        {
          published_at: new Date().toISOString(),
          published: true,
        }
      )

      this.product_data = {
        product_title: this.$translate("user_guide.demo_product.title"),
        product_description: this.$translate("user_guide.demo_product.description"),
        product_images: [empty_image_object("/static/user_guide/demo_product_image_1.jpeg")],
        product_price,
        product_inventory,
        collection_title: this.$translate("user_guide.demo_collection.title"),
        collection_image: "/static/user_guide/demo_collection_image_1.jpeg",
      }

      collection.products.push({
        ...product,
        collections: []
      })

      this.product = product
      this.collection = collection
      this.current_step = 6
    }
  }
}
</script>

<style lang="scss">
  @use "../../../../../styles/_admin-constants" as *;
  @use "../../../../../styles/_global-constants" as *;

  .user-guide {
    position: relative;
    min-height: calc(100vh - 50px);
    width: 100%;
    max-width: $small-desktop;
    padding: 0 $default-size 90px;
    font-family: $main-font;
    margin: auto;
    text-align: center;

    &__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &__logo {
      width: 100px;
      margin: $default-size 0;
    }

    h1.user-guide__title {
      font-size: 35px;
      font-weight: bold;
      color: $pure-black;
      text-align: center;
      line-height: normal;
      padding: 0;
      margin: $default-size 0 $double-default-size;

      &--large {
        margin: 80px 0;
        font-size: 50px;
      }

      &--margin-top {
        margin-top: 60px;
      }

      @media (max-width: $mobile--large) {
        font-size: $font-size--very-large;

        &--large {
          margin: 60px 0 $default-size;
          font-size: 35px;
        }
      }
    }

    &__footer {
      position:fixed;
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      bottom: 0;
      left: 0;
      gap: $default-size;
      background: $pure-white;
      padding: $half-default-size 0;
      z-index: 3;
      box-shadow: 0px 0px 30px 0 rgba($pure-black, 0.15);
    }

    &__input {
      padding: $half-default-size $default-size;
      border: none;
      border-bottom: 1px solid $pure-black;
      font-size: $font-size--large;
      transition: 0.2s ease-in-out;
      text-align: center;
      width: 100%;

      &:focus {
        border-bottom-color: $blue--darker;
      }

      &:focus-visible {
        outline: none;
      }

      &::placeholder {
        color: $black--light;
      }

      &-wrapper {
        position: relative;
        max-width: 300px;
        width: 100%;
        margin: 0 auto;

        &:first-child {
          margin-bottom: $default-size;
        }
      }
    }

    &__label {
      position: absolute;
      bottom: 10px;
      right: 0;
      font-size: $font-size--medium;
      color: $black--light;
    }
  }
</style>
